<div
  class="mb-0 w-full items-center"
  [ngClass]="{
    'form__element--group': !fullWidth,
    'p-0': noPadding
  }"
>
  <label
    *ngIf="showLabel"
    [for]="labelFor"
    class="form__element--label"
    [ngClass]="{
      'p-0': noPadding,
      'whitespace-normal': fullWidth,
      'min-w-full': fullWidth
    }"
  >
    {{ label }}&nbsp;<ng-container *ngIf="required"
      ><span class="text-red">{{ '*' }}</span></ng-container
    >
    <span *ngIf="info && info?.length > 0">
      <icon class="si ml-1 text-grey-500" svgIcon="info" [sbTooltip]="info"></icon>
    </span>
  </label>

  <div
    class="form__element--input-group"
    [ngClass]="{
      dark: dark,
      'has-danger': hasDanger || invalid,
      'p-0': noPadding
    }"
    [ngStyle]="{
      'padding-right': fullWidth ? '0' : null,
      flex: fullWidth ? '0 0 100%' : null,
      'max-width': fullWidth ? '100%' : null
    }"
    #input
  >
    <ng-content></ng-content>
    <div class="form__validation-message form-control-feedback" *ngIf="overrideError">
      {{ overrideErrorMessage }}
    </div>
    <control-messages
      *ngIf="control"
      [control]="control"
      [when]="errorWhen"
      [parentCheck]="parentCheck"
    ></control-messages>
  </div>
</div>
