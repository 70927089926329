import { NgClass, NgIf, NgStyle } from '@angular/common';
import { AfterViewInit, Component, ElementRef, Input, OnDestroy, ViewChild, ViewEncapsulation } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { TooltipModule } from '@sb/tooltip';
import { Subject, takeUntil } from 'rxjs';

import { IconComponent } from '../../../+authenticated/shared/icon.component';
import { ControlMessages, RuleOption } from '../../../forms/control-messages.component';

@Component({
  selector: 'sb-form-field',
  templateUrl: './sb-form-field.component.html',
  standalone: true,
  imports: [NgIf, ControlMessages, NgStyle, NgClass, IconComponent, TooltipModule, ControlMessages],
  encapsulation: ViewEncapsulation.None,
})
export class SbFormFieldComponent implements AfterViewInit, OnDestroy {
  public labelFor: string;

  @ViewChild(ControlMessages, { static: false })
  public controlMessages: ControlMessages;

  @Input()
  public control: AbstractControl;
  @Input()
  public label: string;
  @Input()
  public info: string;
  @Input()
  public errorWhen: RuleOption[] = ['dirty'];
  @Input()
  public parentCheck: string[];
  @Input()
  public dark = false;
  @Input()
  public set overrideError(value: boolean) {
    this._overrideError = value;
    this.updateAriaInvalid();
  }
  public get overrideError() {
    return this._overrideError;
  }
  private _overrideError: boolean;
  @Input()
  public overrideErrorMessage: string;
  @Input()
  public fullWidth = false;
  @Input()
  public required = false;
  @Input()
  public showLabel = true;
  @Input()
  public noPadding = false;

  @ViewChild('input', { static: true })
  public inputGroupEl: ElementRef;

  private destroyed$ = new Subject<void>();

  public get hasDanger() {
    if (!this.controlMessages) {
      return false;
    }
    if (!this.controlMessages.showError && !this.control.valid) {
      this.controlMessages.checkMessage();
    }

    return false;
  }

  public get invalid() {
    return this.controlMessages?.showError || this.overrideError;
  }

  private showError = false;

  private get inputEl() {
    const mainElement = this.inputGroupEl.nativeElement.querySelector('input, select, textarea, time-input');
    if (mainElement) {
      return mainElement;
    }
    // secondary element used by sb-select
    return this.inputGroupEl.nativeElement.querySelector('button');
  }

  public ngAfterViewInit(): void {
    if (this.inputEl?.id) {
      this.labelFor = this.inputEl.id;
    }

    void this.controlMessages?.showError$.pipe(takeUntil(this.destroyed$)).subscribe((showError) => {
      this.showError = showError;
      this.updateAriaInvalid();
    });
  }

  private updateAriaInvalid(): void {
    if (this.inputEl) {
      this.inputEl.setAttribute('aria-invalid', this.showError || this.overrideError ? 'true' : undefined);
    }
  }

  public ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
